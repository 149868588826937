import axios from 'axios'
import api from '@/api'

const CancelToken = axios.CancelToken
let cancelEbsCatalogItemUploadFileFile
let cancelEbsCatalogItemGetAttachmentFile

const data = {
  data: {
    workspaceSettings: [],
    workspaceSettingsSingle: {},
    certificateType: 'default',
    certificateContentId: '',
    notificationType: 'default',
    notification: '',
    notificationIdentifier: '',
    notificationDetails: {},
    disableCertificates: false,
    feedbackForm: 'workspaceDefault',
    files: [],
    hiddenCourseFiles: [],
    urls: [],
    owner: '',
    versionId: '',
    usage: {
      workflows: null,
      autoEnrolments: null
    },
    enrolments: ''
  },
  custom: {
    sharedToWorkspaces: []
  },
  default: {}
}

const ebsCatalogItem = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsCatalogItem: state => {
      return state.data
    },
    getCatalogItemCourseOriginWorkspaceId: state => {
      return state.data.courseOriginWorkspaceId
    },
    getEbsCatalogItemUsageWorkflows: state => {
      const usage = state.data.usage

      return usage ? usage.workflows : []
    },
    getEbsCatalogItemUsageAutoEnrolments: state => {
      const usage = state.data.usage

      return usage ? usage.autoEnrolments : []
    },
    getEbsCatalogItemUsageWorkflowsLoggedInWorkspace: (state, getters) => {
      const userWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
      const usage = getters.getEbsCatalogItemUsageWorkflows

      return usage ? usage.find(obj => obj.workspace_id === userWorkspaceId) : []
    },
    getEbsCatalogItemUsageWorkflowsForWorkspace: (state, getters) => workspaceId => {
      const usage = getters.getEbsCatalogItemUsageWorkflows

      return usage ? usage.find(obj => obj.workspace_id === workspaceId) : null
    },
    getEbsCatalogItemUsageAutoEnrolmentsLoggedInWorkspace: (state, getters) => {
      const userWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
      const usage = getters.getEbsCatalogItemUsageAutoEnrolments

      return usage ? usage.find(obj => obj.workspace_id === userWorkspaceId) : []
    },
    getEbsCatalogItemUsageAutoEnrolmentsForWorkspace: (state, getters) => workspaceId => {
      const usage = getters.getEbsCatalogItemUsageAutoEnrolments

      return usage ? usage.find(obj => obj.workspace_id === workspaceId) : null
    },
    getEbsCatalogItemEnrolments: state => {
      const enrolments = state.data.enrolments

      const enrolmentsMapped = enrolments ? enrolments.map(enrolmentObj => {
        return {
          ...enrolmentObj,
          enrolments: [
            {
              status: 'Not started',
              enrolmentsCount: enrolmentObj.not_started
            },
            {
              status: 'In progress',
              enrolmentsCount: enrolmentObj.in_progress
            },
            {
              status: 'Awaiting feedback',
              enrolmentsCount: enrolmentObj.awaiting_feedback
            }
          ]
        }
      }) : []

      return enrolmentsMapped
    },
    getEbsCatalogItemEnrolmentsLoggedInWorkspace: (state, getters) => {
      const userWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
      const enrolments = getters.getEbsCatalogItemEnrolments

      return enrolments ? enrolments.find(obj => obj.workspace_id === userWorkspaceId) : []
    },
    getEbsCatalogItemEnrolmentsForWorkspace: (state, getters) => workspaceId => {
      const enrolments = getters.getEbsCatalogItemEnrolments

      return enrolments ? enrolments.find(obj => obj.workspace_id === workspaceId) : []
    },
    getEbsCatalogItemId: state => {
      return state.data.id
    },
    getEbsCatalogItemVersionId: state => {
      return state.data.versionId
    },
    getEbsCatalogItemFiles: state => {
      return state.data.files
    },
    getEbsCatalogItemHiddenCourseFiles: state => {
      return state.data.hiddenCourseFiles
    },
    getEbsCatalogItemRequireApproval: state => {
      return state.data.requireApproval
    },
    getEbsCatalogItemLatestEnrolment: state => {
      return state.data.latestEnrolment
    },
    getEbsCatalogItemLatestEnrolmentId: state => {
      const latestEnrolment = state.data.latestEnrolment
      return latestEnrolment ? latestEnrolment.id : undefined
    },
    getEbsCatalogItemCertificateType: state => {
      return state.data.certificateType
    },
    getEbsCatalogItemCertificateDisabled: state => {
      return state.data.disableCertificates
    },
    getEbsCatalogItemCertificateId: state => {
      return state.data.certificateContentId
    },
    getEbsCatalogItemNotificationType: state => {
      return state.data.notificationType
    },
    getEbsCatalogItemNotificationId: state => {
      return state.data.notification
    },
    getEbsCatalogItemNotificationIdentifier: state => {
      return state.data.notificationIdentifier
    },
    getEbsCatalogItemNotificationDetails: state => {
      return state.data.notificationDetails
    },
    getEbsCatalogItemLatestEnrolmentLearnerId: state => {
      const latestEnrolment = state.data.latestEnrolment
      return latestEnrolment ? latestEnrolment.learner : undefined
    },
    getEbsCatalogItemLatestEnrolmentState: state => {
      const latestEnrolment = state.data.latestEnrolment
      return latestEnrolment ? latestEnrolment.state : undefined
    },
    getEbsCatalogItemWorkspacesSettings: state => {
      const workspaces = state.data.workspaceSettings

      return workspaces || []
    },
    getEbsCatalogItemWorkspaceSettingsOrgUnits: state => workspaceId => {
      const workspaces = state.data.workspaceSettings
      const selectedWorkspace = workspaces.find(obj => obj.id === workspaceId || obj.workspaceId === workspaceId)

      return selectedWorkspace ? (selectedWorkspace.settings ? selectedWorkspace.settings.availableInOrganisationUnits : selectedWorkspace.availableInOrganisationUnits) : null
    },
    getEbsCatalogItemWorkspaceSettingsVisibility: state => workspaceId => {
      const workspaces = state.data.workspaceSettings
      const selectedWorkspace = workspaces.find(obj => obj.id === workspaceId || obj.workspaceId === workspaceId)

      return selectedWorkspace ? (selectedWorkspace.settings ? selectedWorkspace.settings.visible : selectedWorkspace.visible) : false
    },
    getEbsCatalogItemWorkspaceSettingsRequireApproval: state => workspaceId => {
      const workspaces = state.data.workspaceSettings
      const selectedWorkspace = workspaces.find(obj => obj.id === workspaceId || obj.workspaceId === workspaceId)

      return selectedWorkspace ? (selectedWorkspace.settings ? selectedWorkspace.settings.requireApproval : selectedWorkspace.requireApproval) : false
    },
    getEbsCatalogItemFeedbackForm: state => {
      return state.data.feedbackForm
    },
    getEbsCatalogItemUrls: state => {
      return state.data.urls
    },
    getEbsCatalogItemFilesUploading: state => {
      const files = state.data.files
      return files.length ? Boolean(files.find(obj => obj.loaded && obj.loaded !== obj.total)) : false
    },
    getEbsCatalogItemCustomSharedToWorkspaces: state => {
      return state.custom.sharedToWorkspaces
    },
    getEbsCatalogItemOriginCustomerId: state => {
      return state.data.originCustomerId
    }
  },
  mutations: {
    EBS_CATALOGITEM_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.custom = JSON.parse(JSON.stringify(data.custom))
      state.default = JSON.parse(JSON.stringify(data.data))
    },
    EBS_CATALOGITEM_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    EBS_CATALOGITEM_SET (state, payload) {
      const stateData = JSON.parse(JSON.stringify(state))
      const loggedInWorkspaceId = payload.workspaceId
      let payloadData = { ...data.data, ...payload.data }
      const selectedWorkspaceSettings = payloadData.workspaceSettings.find(obj => obj.workspaceId === loggedInWorkspaceId)

      if (!payloadData.certificateContentId) {
        payloadData.certificateContentId = null
        payloadData.certificateType = 'default'
      } else {
        payloadData.certificateType = 'selected'
      }
      if (!payloadData.notification) {
        payloadData.notification = null
        payloadData.notificationType = 'default'
      } else {
        payloadData.notificationType = 'selected'
      }
      payloadData.notificationDetails = {}
      payloadData.notificationIdentifier = ''
      payloadData.feedbackForm = state.data.feedbackForm && state.data.feedbackForm !== 'workspaceDefault' ? state.data.feedbackForm : (selectedWorkspaceSettings ? selectedWorkspaceSettings.feedbackForm : 'workspaceDefault')
      payloadData.workspaceSettings = payloadData.workspaceSettings || state.data.workspaceSettings || []
      payloadData.files = state.data.files ?? []
      payloadData.urls = stateData.data.urls.length ? stateData.data.urls : []

      state.data = payloadData
      state.default = payloadData
    },
    EBS_CATALOGITEM_SET_WORKSPACESETTINGS_ORGUNITS (state, payload) {
      let workspaces = state.data.workspaceSettings
      let selectedWorkspace = workspaces && workspaces.length ? workspaces.find(obj => obj.id === payload.workspaceId) : null

      selectedWorkspace.settings.availableInOrganisationUnits = payload.value
    },
    EBS_CATALOGITEM_SET_WORKSPACESETTINGS_VISIBILITY (state, payload) {
      let workspaces = state.data.workspaceSettings
      let selectedWorkspace = workspaces && workspaces.length ? workspaces.find(obj => obj.id === payload.workspaceId) : null

      selectedWorkspace.settings.visible = payload.value
    },
    EBS_CATALOGITEM_SET_WORKSPACESETTINGS_REQUIREAPPROVAL (state, payload) {
      let workspaces = state.data.workspaceSettings
      let selectedWorkspace = workspaces && workspaces.length ? workspaces.find(obj => obj.id === payload.workspaceId) : null

      selectedWorkspace.settings.requireApproval = payload.value
    },
    EBS_CATALOGITEM_SET_WORKSPACESETTINGS (state, payload) {
      const existingWorkspaceSettings = state.data.workspaceSettings
      const loggedInWorkspaceId = payload.workspaceId
      let payloadWorkspaceSettings = JSON.parse(JSON.stringify(payload.data))
      const selectedWorkspaceSettings = payloadWorkspaceSettings.find(obj => obj.settings && obj.id === loggedInWorkspaceId)
      const workspaceSettingsCertificateId = selectedWorkspaceSettings ? selectedWorkspaceSettings.settings.certificateContentId : null
      const workspaceSettingsNotificationId = selectedWorkspaceSettings ? selectedWorkspaceSettings.settings.notification : null
      const workspaceSettingsFeedbackForm = selectedWorkspaceSettings ? selectedWorkspaceSettings.settings.feedbackForm : 'workspaceDefault'
      const workspaceSettingsLinks = selectedWorkspaceSettings ? selectedWorkspaceSettings.settings.urls : []
      const workspaceSettingsHiddenCourseFiles = selectedWorkspaceSettings ? selectedWorkspaceSettings.settings.hiddenCourseFiles : []
      const disableCertificates = selectedWorkspaceSettings ? selectedWorkspaceSettings.settings.disableCertificates : false
      const courseLinks = JSON.parse(JSON.stringify(state.data.urls))
      const courseFiles = JSON.parse(JSON.stringify(state.data.files))
      let mergedLinks = courseLinks
      let mergedFiles = courseFiles

      if (!payloadWorkspaceSettings.find(obj => obj.id === loggedInWorkspaceId)) {
        if (existingWorkspaceSettings.find(obj => obj.workspaceId === loggedInWorkspaceId)) {
          payloadWorkspaceSettings.push({
            id: loggedInWorkspaceId,
            settings: existingWorkspaceSettings.find(obj => obj.workspaceId === loggedInWorkspaceId)
          })
        } else {
          payloadWorkspaceSettings.push({
            id: loggedInWorkspaceId,
            settings: {
              requireApproval: false,
              visible: false,
              workspaceId: loggedInWorkspaceId,
              availableInOrganisationUnits: []
            }
          })
        }
      }

      if (payloadWorkspaceSettings && payloadWorkspaceSettings.length) {
        for (let workspace of payloadWorkspaceSettings) {
          workspace.settings = workspace.settings || {
            requireApproval: false,
            visible: false,
            workspaceId: workspace.id,
            availableInOrganisationUnits: []
          }
        }
      }
      if (workspaceSettingsLinks && workspaceSettingsLinks.length) {
        for (let link of workspaceSettingsLinks) {
          if (link.providedByCourse) {
            let courseSelectedUrlIndex = mergedLinks.findIndex(obj => obj.url === link.url)
            if (courseSelectedUrlIndex > -1) {
              mergedLinks[courseSelectedUrlIndex].visibility = link.visibility
            }
          }
        }
      }

      state.data.disableCertificates = disableCertificates
      state.data.certificateContentId = workspaceSettingsCertificateId
      state.data.certificateType = disableCertificates ? '' : workspaceSettingsCertificateId ? 'selected' : 'default'
      state.data.files = mergedFiles || []
      state.data.urls = mergedLinks || []
      state.data.hiddenCourseFiles = workspaceSettingsHiddenCourseFiles
      state.data.notification = workspaceSettingsNotificationId
      state.data.notificationIdentifier = ''
      state.data.notificationType = workspaceSettingsNotificationId === null ? null : (workspaceSettingsNotificationId === 'default' ? 'default' : 'selected')
      state.data.notificationDetails = {}
      state.data.feedbackForm = state.data.feedbackForm && state.data.feedbackForm !== 'workspaceDefault' ? state.data.feedbackForm : workspaceSettingsFeedbackForm
      state.data.workspaceSettings = payloadWorkspaceSettings
    },
    EBS_CATALOGITEM_SET_CERTIFICATETYPE (state, payload) {
      state.data.certificateType = payload
      state.data.disableCertificates = false
    },
    EBS_CATALOGITEM_SET_CERTIFICATEID (state, payload) {
      state.data.certificateContentId = payload
    },
    EBS_CATALOGITEM_SET_NOTIFICATIONTYPE (state, payload) {
      if (payload === null || payload === 'default') {
        state.data.notification = payload
      } else {
        state.data.notification = ''
      }
      state.data.notificationType = payload
    },
    EBS_CATALOGITEM_SET_NOTIFICATIONDETAILS (state, payload) {
      state.data.notificationDetails = payload
    },
    EBS_CATALOGITEM_SET_NOTIFICATIONID (state, payload) {
      state.data.notification = payload
    },
    EBS_CATALOGITEM_SET_NOTIFICATIONIDENTIFIER (state, payload) {
      state.data.notificationIdentifier = payload
    },
    EBS_CATALOGITEM_SET_CERTIFICATEDISABLED (state, payload) {
      state.data.disableCertificates = payload
      state.data.certificateType = ''
    },
    EBS_CATALOGITEM_SET_WORKSPACESETTINGS_SINGLE (state, payload) {
      state.data.workspaceSettingsSingle = payload
    },
    EBS_CATALOGITEM_ADD_URL (state, payload) {
      state.data.urls.push(payload)
    },
    EBS_CATALOGITEM_UPDATE_URL (state, payload) {
      let urls = state.data.urls
      const selectedUrlIndex = urls.findIndex(obj => obj.url === payload.url)

      urls.splice(selectedUrlIndex, 1, payload)
    },
    EBS_CATALOGITEM_REMOVE_URL (state, payload) {
      let urls = state.data.urls
      let selectedUrlIndex = urls.findIndex(obj => obj.url === payload)

      urls.splice(selectedUrlIndex, 1)
    },
    EBS_CATALOGITEM_ADD_FILE (state, payload) {
      state.data.files.push(payload)
    },
    EBS_CATALOGITEM_REMOVE_FILE (state, payload) {
      let files = JSON.parse(JSON.stringify(state.data.files))
      let selectedFileIndex = files.findIndex(obj => obj.url === payload)

      state.data.files.splice(selectedFileIndex, 1)
    },
    EBS_CATALOGITEM_SET_FILE_PROGRESS (state, payload) {
      let files = state.data.files
      let selectedFile = files.find(obj => obj.name === payload.name)

      selectedFile.loaded = payload.loaded
      selectedFile.total = payload.total
    },
    EBS_CATALOGITEM_SET_FILES (state, payload) {
      state.data.files = payload || []
    },
    EBS_CATALOGITEM_SET_URLS (state, payload) {
      state.data.urls = payload.filter(obj => obj.providedByCourse !== null)
    },
    EBS_CATALOGITEM_FEEDBACK_FORM_SET (state, payload) {
      state.data.feedbackForm = payload
    },
    EBS_CATALOGITEM_SET_CUSTOM_SHAREDTOWORKSPACES (state, payload) {
      state.custom.sharedToWorkspaces = payload.workspaceIds || []
    },
    EBS_CATALOGITEM_SET_USAGE_WORKFLOWS (state, payload) {
      state.data.usage.workflows = payload
    },
    EBS_CATALOGITEM_SET_USAGE_AUTOENROLMENTS (state, payload) {
      state.data.usage.autoEnrolments = payload
    },
    EBS_CATALOGITEM_SET_ENROLMENTS (state, payload) {
      state.data.enrolments = payload
    }
  },
  actions: {
    ebsCatalogItemGet ({ commit, rootState, getters }, data) {
      return new Promise((resolve, reject) => {
        const catalogId = data || rootState.route.params.courseId
        const loggedInUserWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        const loggedInUserCustomerId = getters.getAuthUserSecondStageTokenDataCustomerId

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog-item/${catalogId}`
        }).then(response => {
          commit('EBS_CATALOGITEM_SET', { workspaceId: loggedInUserWorkspaceId, customerId: loggedInUserCustomerId, data: response.data })
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemGetUsageWorkflows ({ commit }, catalogItemId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/workflows/usage/${catalogItemId}`
        }).then(response => {
          commit('EBS_CATALOGITEM_SET_USAGE_WORKFLOWS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemGetUsageAutoEnrolments ({ commit }, catalogItemId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/autoenrolments/usage/${catalogItemId}`
        }).then(response => {
          commit('EBS_CATALOGITEM_SET_USAGE_AUTOENROLMENTS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemGetEnrolments ({ commit }, catalogItemId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/courses/catalog-item-by-enrolments/${catalogItemId}`
        }).then(response => {
          commit('EBS_CATALOGITEM_SET_ENROLMENTS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemGetFiles ({ commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        const catalogId = data || rootState.route.params.catalogId

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/${catalogId}/list-files`
        }).then(response => {
          commit('EBS_CATALOGITEM_SET_FILES', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemGetUrls ({ commit, getters, rootState }, data) {
      return new Promise((resolve, reject) => {
        const catalogId = data || rootState.route.params.catalogId

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/${catalogId}/list-urls`
        }).then(response => {
          const urls = response.data
          const loggedInUserWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
          const catalogItemCourseOriginWorkspace = getters.getCatalogItemCourseOriginWorkspaceId
          const catalogItemsUrls = getters.getEbsCatalogItemUrls

          if (
              catalogItemCourseOriginWorkspace !== loggedInUserWorkspaceId &&
              !urls.length &&
              catalogItemsUrls.length
          ) {
            resolve(response.data)
          } else {
            commit('EBS_CATALOGITEM_SET_URLS', response.data)
            resolve(response.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemShare ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data.catalogItemId
        const workspaceIds = data.workspaceIds

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/catalog/${catalogItemId}/add-shared-workspace`,
          data: {
            workspaceIds
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Course has been shared',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemUnshare ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data.catalogItemId
        const workspaces = data.workspaces

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/catalog/${catalogItemId}/unshare-from-workspaces`,
          data: {
            workspaces
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Course has been unshared',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemArchive ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data.catalogItemId
        const courseName = data.courseName

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v3/catalog-item/${catalogItemId}/archive`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: `Archiving started for ${courseName}`,
            message: `This process may take several minutes.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemClearSuspendData ({ dispatch }, catalogItemId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.learningactivities}/internal/clear-suspend-data-for-catalog-item/${catalogItemId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Suspend data successfully cleared',
            message: ``,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemGetSharedWorkspaces ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/${catalogItemId}/shared-workspace`
        }).then(response => {
          commit('EBS_CATALOGITEM_SET_CUSTOM_SHAREDTOWORKSPACES', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemDownloadMultipleModules ({ getters }, data) {
      return new Promise((resolve, reject) => {
        const courseCatalogId = data.courseCatalogId
        const courseName = data.courseName

        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.courseCatalog}/v2/lms-connector/${courseCatalogId}`,
          responseType: 'arraybuffer'
        }).then(response => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.getElementById('download-course')
            link.href = url
            link.setAttribute('download', `${courseName}-multi.zip`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `${courseName}.zip`)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemDownloadSingleModule ({ getters }, data) {
      return new Promise((resolve, reject) => {
        const courseCatalogId = data.courseCatalogId
        const courseName = data.courseName

        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.courseCatalog}/v2/lms-connector-multi/${courseCatalogId}`,
          responseType: 'arraybuffer'
        }).then(response => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.getElementById('download-course')
            link.href = url
            link.setAttribute('download', `${courseName}.zip`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `${courseName}.zip`)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemGetWorkspaceSettings ({ commit, getters }, catalogItemId) {
      return new Promise((resolve, reject) => {
        const loggedInWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/${catalogItemId}/workspaces`
        }).then(response => {
          commit('EBS_CATALOGITEM_SET_WORKSPACESETTINGS', { data: response.data, workspaceId: loggedInWorkspaceId })
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemGetWorkspaceSettingsSingle ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data.catalogItemId
        const workspaceId = data.workspaceId || getters.getAuthUserSecondStageTokenDataWorkspaceId
        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/${catalogItemId}/workspace/${workspaceId}`
        }).then(response => {
          commit('EBS_CATALOGITEM_SET_WORKSPACESETTINGS_SINGLE', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemUpdateWorkspaceSettings ({ getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const dataObj = data ? JSON.parse(JSON.stringify(data)) : undefined
        const catalogItemId = dataObj && dataObj.catalogItemId ? dataObj.catalogItemId : undefined
        const workspaceId = dataObj && dataObj.workspaceId ? dataObj.workspaceId : getters.getAuthUserSecondStageTokenDataWorkspaceId
        const approvalRequired = dataObj && dataObj.approvalRequired ? dataObj.approvalRequired : false
        const certificateContentId = dataObj && dataObj.certificateContentId ? dataObj.certificateContentId : null
        const disableCertificates = dataObj && dataObj.disableCertificates ? dataObj.disableCertificates : false
        const visible = dataObj && dataObj.visible !== undefined ? dataObj.visible : undefined
        const organisationUnits = dataObj && dataObj.organisationUnits !== undefined ? dataObj.organisationUnits : undefined
        let feedbackForm = dataObj && dataObj.feedbackForm !== undefined ? dataObj.feedbackForm : 'workspaceDefault'
        let notificationIdentifier = dataObj && dataObj.notificationIdentifier !== undefined ? dataObj.notificationIdentifier : null
        let notification = dataObj && dataObj.notification !== undefined ? dataObj.notification : null

        if (
          (notification && notificationIdentifier) &&
        (notification === notificationIdentifier)
        ) {
          notification = 'default'
        }

        const payloadData = {
          workspaceId,
          approvalRequired,
          certificateContentId,
          notification,
          feedbackForm,
          disableCertificates
        }

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/catalog/${catalogItemId}/workspace/${workspaceId}`,
          data: payloadData
        }).then(response => {
          if (visible !== undefined && organisationUnits !== undefined) {
            dispatch('ebsCatalogItemUpdateWorkspaceSettingsVisible', { catalogItemId, workspaceId, visible, organisationUnits }).then(() => {
              resolve(response.data)
            }).catch(error => {
              reject(error)
            })
          } else {
            resolve(response.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemUpdateWorkspaceSettingsVisible (ctx, data) {
      return new Promise((resolve, reject) => {
        const dataObj = JSON.parse(JSON.stringify(data))
        const catalogItemId = dataObj.catalogItemId
        const workspaceId = dataObj.workspaceId
        const visible = dataObj.visible
        const organisationUnits = dataObj.organisationUnits || []

        axios({
          method: 'patch',
          url: `${api.courseCatalog}/v2/catalog/${catalogItemId}/workspace/${workspaceId}/visible`,
          data: {
            visible,
            organisationUnits
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemUpdate ({ state, getters, dispatch }, data) {
      return new Promise((resolve) => {
        const loggedInWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        const catalogId = data && data.catalogId ? data.catalogId : state.data.id
        const workspaces = state.data.workspaceSettings
        const stateData = JSON.parse(JSON.stringify(state.data))
        const certificateType = stateData.certificateType
        const disableCertificates = stateData.disableCertificates
        const certificateContentId = certificateType === 'selected' ? stateData.certificateContentId : null
        const feedbackForm = state.data.feedbackForm
        const notification = state.data.notification
        const notificationIdentifier = state.data.notificationIdentifier
        const stateDataNewFiles = stateData.files.filter(obj => obj.new)
        const courseFiles = getters.getEbsCourseFiles
        const courseFilesHidden = courseFiles.length ? courseFiles.filter(obj => obj.hide) : []
        const stateDataUrls = stateData.urls

        const handleResolve = () => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Course updated',
            message: ``,
            status: 'success',
            id: 'course-update'
          })
          resolve()
        }

        dispatch('ebsCatalogItemCancelRequests')

        let settingsActionsArr = []
        let settingsActionsCounter = 0
        let otherActionsArr = []
        let otherActionsCounter = 0

        if (workspaces.length) {
          const selectedWorkspace = workspaces.find(obj => obj.id === loggedInWorkspaceId)

          if (selectedWorkspace) {
            settingsActionsArr.push({
              name: 'ebsCatalogItemUpdateWorkspaceSettings',
              data: {
                catalogItemId: catalogId,
                workspaceId: selectedWorkspace.id,
                visible: selectedWorkspace.settings.visible,
                organisationUnits: selectedWorkspace.settings.availableInOrganisationUnits,
                approvalRequired: selectedWorkspace.settings.requireApproval,
                certificateContentId: certificateContentId,
                disableCertificates: disableCertificates,
                feedbackForm: feedbackForm,
                notification: notification,
                notificationIdentifier: notificationIdentifier
              }
            })
          }
        }

        if (courseFilesHidden.length) {
          otherActionsArr.push({
            name: 'ebsCatalogItemHideFiles',
            data: courseFilesHidden.map(obj => obj.url)
          })
        }

        if (stateDataNewFiles.length) {
          for (let file of stateDataNewFiles) {
            otherActionsArr.push({
              name: 'ebsCatalogItemAddFile',
              data: {
                catalogId: catalogId,
                workspaceId: loggedInWorkspaceId,
                name: file.name,
                url: file.url,
                size: file.size
              }
            })
          }
        }

        otherActionsArr.push({
          name: 'ebsCatalogItemAddUrls',
          data: {
            catalogId: catalogId,
            workspaceId: loggedInWorkspaceId,
            urls: stateDataUrls
          }
        })

        for (let action of settingsActionsArr) {
          dispatch(action.name, action.data).finally(() => {
            settingsActionsCounter++
            if (settingsActionsCounter === settingsActionsArr.length) {
              if (otherActionsArr.length) {
                for (let action of otherActionsArr) {
                  dispatch(action.name, action.data).finally(() => {
                    otherActionsCounter++
                    if (otherActionsCounter === otherActionsArr.length) {
                      handleResolve()
                    }
                  })
                }
              } else {
                handleResolve()
              }
            }
          })
        }
      })
    },
    ebsCatalogItemAddUrls ({ state }, data) {
      return new Promise((resolve, reject) => {
        const catalogId = data ? data.catalogId : ''
        const workspaceId = data ? data.workspaceId : ''
        const urls = data ? data.urls : state.data.urls

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/catalog/${catalogId}/workspace/${workspaceId}/set-urls`,
          data: {
            urls
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemCancelRequests () {
      if (cancelEbsCatalogItemUploadFileFile) cancelEbsCatalogItemUploadFileFile()
      if (cancelEbsCatalogItemGetAttachmentFile) cancelEbsCatalogItemGetAttachmentFile()
    },
    ebsCatalogItemUploadFile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const url = data ? data.action : undefined
        const enctype = data ? data.enctype : undefined
        const method = data ? data.method : undefined
        const form = data ? data.form : undefined

        axios({
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsCatalogItemUploadFileFile = ex
          }),
          method: method,
          url: url,
          headers: {
            'Content-Type': enctype
          },
          data: form,
          onUploadProgress (progressEvent) {
            commit('EBS_CATALOGITEM_SET_FILE_PROGRESS', { name: form.get('file').name, size: form.get('file').size, loaded: progressEvent.loaded, total: progressEvent.total })
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemRemoveFile ({ commit, getters, state }, url) {
      return new Promise((resolve, reject) => {
        const catalogId = getters.getEbsCourseCatalogItemId
        const loggedInUserWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        const files = JSON.parse(JSON.stringify(state.data.files))
        const selectedFile = files.find(obj => obj.url === url)
        const isSelectedFileNew = selectedFile ? selectedFile.new : false

        if (isSelectedFileNew) {
          commit('EBS_CATALOGITEM_REMOVE_FILE', url)
          resolve()
        } else {
          axios({
            method: 'post',
            url: `${api.courseCatalog}/v2/catalog/${catalogId}/workspace/${loggedInUserWorkspaceId}/remove-file`,
            data: {
              url
            }
          }).then(response => {
            commit('EBS_CATALOGITEM_REMOVE_FILE', url)
            resolve(response.data)
          }).catch(error => {
            reject(error)
          })
        }
      })
    },
    ebsCatalogItemHideFiles ({ getters, rootState }, urls) {
      return new Promise((resolve, reject) => {
        const catalogId = rootState.route.params.catalogId
        const loggedInUserWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/catalog/${catalogId}/workspace/${loggedInUserWorkspaceId}/set-hidden-course-files`,
          data: {
            urls
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemGeneratePresignedPost (ctx, data) {
      return new Promise((resolve, reject) => {
        const mimeType = 'application/pdf'
        const name = data ? data.name : undefined
        const size = data ? data.size : undefined

        axios({
          method: 'post',
          url: `${api.courseCatalog}/catalog-item-files/generatePresignedPost`,
          data: {
            mimeType,
            name,
            size
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemAddUrl ({ commit }, data) {
      commit('EBS_CATALOGITEM_ADD_URL', data)
    },
    ebsCatalogItemUpdateUrl ({ commit }, data) {
      commit('EBS_CATALOGITEM_UPDATE_URL', data)
    },
    ebsCatalogItemRemoveUrl ({ commit }, data) {
      commit('EBS_CATALOGITEM_REMOVE_URL', data)
    },
    ebsCatalogItemSetFile ({ commit }, data) {
      commit('EBS_CATALOGITEM_ADD_FILE', data)
    },
    ebsCatalogItemAddFile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const catalogId = data ? data.catalogId : ''
        const workspaceId = data ? data.workspaceId : ''
        const name = data ? data.name : ''
        const url = data ? data.url : ''
        const size = data ? data.size : ''

        axios({
          method: 'post',
          url: `${api.courseCatalog}/v2/catalog/${catalogId}/workspace/${workspaceId}/add-file`,
          data: {
            name,
            url,
            size
          }
        }).then(response => {
          commit('EBS_COURSE_REMOVE_FILE', url)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemSetWorkspaceSettingsOrgUnits ({ commit }, data) {
      commit('EBS_CATALOGITEM_SET_WORKSPACESETTINGS_ORGUNITS', data)
    },
    ebsCatalogItemSetWorkspaceSettingsVisibility ({ commit }, data) {
      commit('EBS_CATALOGITEM_SET_WORKSPACESETTINGS_VISIBILITY', data)
    },
    ebsCatalogItemSetCertificateType ({ commit }, value) {
      commit('EBS_CATALOGITEM_SET_CERTIFICATETYPE', value)
    },
    ebsCatalogItemSetCertificateDisabled ({ commit }, value) {
      commit('EBS_CATALOGITEM_SET_CERTIFICATEDISABLED', value)
    },
    ebsCatalogItemSetCertificateId ({ commit }, value) {
      commit('EBS_CATALOGITEM_SET_CERTIFICATEID', value)
    },
    ebsCatalogItemSetNotificationType ({ commit }, value) {
      commit('EBS_CATALOGITEM_SET_NOTIFICATIONTYPE', value)
    },
    ebsCatalogItemSetNotificationDetails ({ commit }, value) {
      commit('EBS_CATALOGITEM_SET_NOTIFICATIONDETAILS', value)
    },
    ebsCatalogItemSetNotificationId ({ commit }, value) {
      commit('EBS_CATALOGITEM_SET_NOTIFICATIONID', value)
    },
    ebsCatalogItemSetNotificationIdentifier ({ commit }, value) {
      commit('EBS_CATALOGITEM_SET_NOTIFICATIONIDENTIFIER', value)
    },
    ebsCatalogItemSetWorkspaceSettingsRequireApproval ({ commit }, data) {
      commit('EBS_CATALOGITEM_SET_WORKSPACESETTINGS_REQUIREAPPROVAL', data)
    },
    ebsCatalogItemClear ({ commit }) {
      commit('EBS_CATALOGITEM_CLEAR')
    },
    ebsCatalogItemFeedbackFormSet ({ commit }, data) {
      commit('EBS_CATALOGITEM_FEEDBACK_FORM_SET', data)
    }
  }
}

export default ebsCatalogItem
